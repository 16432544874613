import { createSlice } from "@reduxjs/toolkit";
import levels from "./levelRoleGroups";

const lan = localStorage.getItem("language") || "id";
const name = localStorage.getItem("languageName") || "Indonesia";


const state = {
  errorMessage: "",
  loading: false,
  airportCache: [],
  airlineCache: [],
  defAirport: null,
  defSeaport: null,
  language: lan,
  name,
  levels,
  rules: {},
};

export const appSlice = createSlice({
  name: "apps",
  initialState: state,
  reducers: {
    setLoading(state) {
      state.loading = true;
    },
    unsetLoading(state) {
      state.loading = false;
    },
    setAirport(state, payload) {
      state.airportCache = payload.payload;
    },
    setAirline(state, payload) {
      state.airlineCache = payload.payload;
    },
    setLanguage(state, payload) {
      console.log(payload);
      state.language = payload.payload.language;
      state.name = payload.payload.name;
      localStorage.setItem("language", payload.payload.language);
      localStorage.setItem("languageName", payload.payload.name);
    },
    setRules(state, payload) {
      state.rules = { ...payload.payload };
    },
    setDefAirport(state, payload) {
      state.defAirport = payload.payload?.data;
    },
    setDefSeaport(state, payload) {
      state.defSeaport = payload.payload?.data;
    },
  },
});

export default appSlice.reducer;
