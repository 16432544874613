import { createSlice } from "@reduxjs/toolkit";

const state = {
  selectedMenu: "",
  selectedMenuKeys: "",
  openKeys: [""],
  breadcrumbs: { key: "", items: [], title: "" },
}

export const navigationSlice = createSlice({
  name: "nav",
  initialState: state,
  reducers: {
    setSelectedMenu(state, action) {
      state.selectedMenu = action.payload;
    },
    setSelectedMenuKeys(state, action) {
      state.selectedMenuKeys = action.payload;
    },
    setOpenKeys(state, action) {
      state.openKeys = action.payload;
    },
    setBreadcrumbs(state, action) {
      state.breadcrumbs = { ...state.breadcrumbs, items: action.payload };
    },
    setBreadcrumbsKey(state, action) {
      state.breadcrumbs = { ...state.breadcrumbs, key: action.payload };
    },
    setBreadcrumbsTitle(state, action) {
      state.breadcrumbs = { ...state.breadcrumbs, title: action.payload };
    },
  },
});

export default navigationSlice.reducer;
