import Loading from "@/assets/images/LoadingPPS.gif";

export default function SpinnerComponent() {
    return (
        <div
            style={{
                boxSizing: "border-box",
                width: "100vw",
                height: "100vh",
                backgroundColor: "#FFFFFF",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <img src={Loading} width={"30%"} alt="loading..." />
        </div>
    );
}
