// ** Reducers Imports
import apps from "./entity/appStates";
import auth from "./entity/authStates";
import nav from "./entity/navigationStates";

const rootReducer = {
  auth,
  apps,
  nav,
};

export default rootReducer;
