import { createSlice } from "@reduxjs/toolkit";
import { levelToRole } from "../../levelConverts";

const state = {
  userdata: false,
  token: "",
  apps: "",
  initComplete: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState: state,
  reducers: {
    successLogin(state, action) {
      const { userdata } = action.payload;
      const levelStr = levelToRole(userdata.level || 0);
      state.userdata = { ...userdata, levelStr };
      state.token = action.payload.token;
    },
    setApps(state, action) {
      state.apps = action.payload;
    },
    doLogout(state) {
      state.userdata = false;
      state.token = "";
      window.localStorage.removeItem("token");
    },
    initComplete(state) {
      state.initComplete = true;
    },
  },
});

// export const login
export default authSlice.reducer;
