import levelRoleGroups from "./redux/entity/levelRoleGroups";

const levelRoles = levelRoleGroups();

export const roleToLevel = (roles) => {
  return levelRoles
    .filter(({ value }) => {
      return Array.isArray(roles) && roles.indexOf(value) >= 0;
    })
    .reduce((acc, val) => {
      acc += parseInt(val.level);
    }, 0);
};

export const levelToRole = (level) => {
  return levelRoles
    .reduce((acc, val) => {
      if ((val.level & level) > 0) {
        acc.push(val.value);
      }
      return acc;
    }, [])
    .join(", ");
};
